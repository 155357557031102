import { useEffect, useState } from "react";
import styles from "../ContactsPageContent/ContactsPageContent.module.scss";
import { officesData } from "../../../Constants/OfficeData";

const ContactsPageContent = () => {
  const [vilniusTime, setVilniusTime] = useState(null);

  useEffect(() => {
    const fetchVilniusTime = async () => {
      try {
        const response = await fetch(
          "https://worldtimeapi.org/api/timezone/Europe/Vilnius"
        );
        const data = await response.json();
        setVilniusTime(new Date(data.utc_datetime));
      } catch (error) {
        console.error("Error fetching Vilnius time:", error);
      }
    };

    const intervalId = setInterval(fetchVilniusTime, 60000);
    fetchVilniusTime();

    return () => clearInterval(intervalId);
  }, []);

  const formattedVilniusTime = vilniusTime
    ? vilniusTime.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        timeZone: "Europe/Vilnius",
      })
    : "Loading...";

  return (
    <div className="container">
      <div className="row mb-4 mb-md-8 mb-xl-16 offset-1">
        <div className="col-10 col-md-8 col-xl-6 ps-md-0 mb-4 mb-lg-8">
          <div className="d-flex flex-column mb-sm-0">
            <div className="font-large mb-5 mb-lg-8">
              Let's create something of significance.
            </div>
            <div className="contact font-small">
              Drop us a line at{" "}
              <a
                className={[styles.contact, "text-decoration-none"].join(" ")}
                href="mailto:info@imas.lt"
              >
                info@imas.lt
              </a>{" "}
              or call{" "}
              <a
                className={[styles.contact, "text-decoration-none"].join(" ")}
                href="tel:+37066240099"
              >
                +370 662 400 99
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row gap-3 ps-lg-0 mb-16 offset-1 pe-1 pe-lg-19 pe-xl-24">
        {officesData.map((office) => (
          <div
            key={office.city}
            className={[
              styles.addressBox,
              office.activeClass,
              "col-11 col-md-8 col-lg-6",
            ].join(" ")}
          >
            <a
              href={office.directions}
              className={["text-decoration-none", styles.addressLink].join(" ")}
              target="blank"
              style={{
                backgroundImage: `url(${office.backgroundImage})`,
              }}
            >
              <div
                className={[styles.Hover, "ps-4 ps-lg-8 pt-6 pt-lg-12"].join(
                  " "
                )}
              >
                <div className={[styles.cityName, "mb-4 mb-lg-8"].join(" ")}>
                  {office.city}
                </div>
                <div
                  className={[styles.contactsInfo, "font-small fw-normal"].join(
                    " "
                  )}
                >
                  <div>{office.street}</div>
                  <div>
                    <a
                      href="tel:+37066240099"
                      className={[
                        styles.phoneLink,
                        "text-decoration-none",
                      ].join(" ")}
                    >
                      {office.tel}
                    </a>
                  </div>
                </div>
                <div
                  className={[
                    styles.timeBox,
                    "d-flex gap-2 me-4 me-lg-8 pt-4 pt-lg-8 pb-4 pb-lg-8 align-items-start",
                  ].join(" ")}
                >
                  <div className={styles.time}>{formattedVilniusTime}</div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactsPageContent;
