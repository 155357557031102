const BalticAmadeusLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="120px"
      height="32px"
      viewBox="0 0 120 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>DE75BBC2-C02D-4150-82F2-234C00314F0E</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="clients-modal"
          transform="translate(-624, -1189)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-8-Copy-2" transform="translate(624, 1140)">
            <g id="Group" transform="translate(0, 49)">
              <path
                d="M31.6083131,20.2288715 C32.5139687,20.2169663 33.397457,20.5091102 34.117482,21.0585807 C34.4227084,21.2980533 34.694717,21.5770709 34.9263483,21.8882899 L35.0297613,21.8882899 L35.0297613,20.5383089 L37.9333427,20.5383089 L37.9333427,31.1201074 L35.0297613,31.1201074 L35.0297613,29.7693248 L34.9263483,29.7693248 C34.694717,30.0805437 34.4227084,30.3595614 34.117482,30.599034 C33.3976331,31.1488381 32.5140262,31.4410213 31.6083131,31.4287432 C28.9115578,31.4287432 26.7342726,29.251458 26.7342726,25.8292082 C26.7342726,22.4069583 28.9115578,20.2288715 31.6083131,20.2288715 Z M32.3297994,28.9396156 C33.905846,28.9396156 35.0257531,27.8173036 35.0257531,25.8292082 C35.0257531,23.8411127 33.9034411,22.7179991 32.3297994,22.7179991 C30.8571658,22.7179991 29.7372588,23.840311 29.7372588,25.8292082 C29.7372588,27.8181053 30.861174,28.9396156 32.3338076,28.9396156 L32.3297994,28.9396156 Z"
                id="Shape"
              ></path>
              <path
                d="M40.2789747,20.5383089 L43.1817544,20.5383089 L43.1817544,21.989298 L43.2859691,21.989298 C43.467396,21.6594031 43.7063002,21.3645737 43.9914223,21.1187045 C44.4884462,20.6834078 45.2355853,20.2264665 46.3963765,20.2264665 C47.7439525,20.2264665 48.6153476,20.724292 49.1749003,21.2221176 C49.4764529,21.503217 49.7289692,21.8326802 49.9220394,22.1969257 L50.0254524,22.1969257 C50.2182299,21.8327072 50.4704785,21.5032397 50.7717899,21.2221176 C51.3329459,20.724292 52.1201675,20.2264665 53.4477023,20.2264665 C55.9568712,20.2264665 57.4920336,21.761629 57.4920336,24.6860533 L57.4920336,31.1201074 L54.5860473,31.1201074 L54.5860473,24.999499 C54.5860473,23.4226507 53.8597511,22.7179991 52.6155881,22.7179991 C51.3714251,22.7179991 50.3340882,23.755336 50.3340882,25.4139527 L50.3340882,31.1201074 L47.4305068,31.1201074 L47.4305068,24.999499 C47.4305068,23.4226507 46.7050123,22.7179991 45.4600477,22.7179991 C44.2150831,22.7179991 43.1785478,23.755336 43.1785478,25.4139527 L43.1785478,31.1201074 L40.2789747,31.1201074 L40.2789747,20.5383089 Z"
                id="Path"
              ></path>
              <path
                d="M64.0214843,20.2288715 C64.9271207,20.2170904 65.8105696,20.5092212 66.5306531,21.0585807 C66.8358796,21.2980533 67.1078881,21.5770709 67.3395194,21.8882899 L67.4429324,21.8882899 L67.4429324,20.5383089 L70.3465138,20.5383089 L70.3465138,31.1201074 L67.4429324,31.1201074 L67.4429324,29.7693248 L67.3395194,29.7693248 C67.1078881,30.0805437 66.8358796,30.3595614 66.5306531,30.599034 C65.8107455,31.1487268 64.9271782,31.4408969 64.0214843,31.4287432 C61.3247289,31.4287432 59.1474437,29.251458 59.1474437,25.8292082 C59.1474437,22.4069583 61.3247289,20.2288715 64.0214843,20.2288715 Z M64.7469788,28.9396156 C66.3230254,28.9396156 67.4429324,27.8173036 67.4429324,25.8292082 C67.4429324,23.8411127 66.3206205,22.7179991 64.7469788,22.7179991 C63.2743452,22.7179991 62.1544381,23.840311 62.1544381,25.8292082 C62.1544381,27.8181053 63.2743452,28.9396156 64.7469788,28.9396156 L64.7469788,28.9396156 Z"
                id="Shape"
              ></path>
              <path
                d="M76.8631381,20.2288715 C77.7690386,20.2169725 78.6527786,20.5091063 79.3731086,21.0585807 C79.678335,21.2980533 79.9503436,21.5770709 80.1819749,21.8882899 L80.2853879,21.8882899 L80.2853879,16.5973906 L83.1889693,16.5973906 L83.1889693,31.1201074 L80.2853879,31.1201074 L80.2853879,29.7693248 L80.1819749,29.7693248 C79.9503436,30.0805437 79.678335,30.3595614 79.3731086,30.599034 C78.6529546,31.1488418 77.769096,31.4410148 76.8631381,31.4287432 C74.1671844,31.4287432 71.9898992,29.251458 71.9898992,25.8292082 C71.9898992,22.4069583 74.1671844,20.2288715 76.8631381,20.2288715 Z M77.5894342,28.9396156 C79.1654809,28.9396156 80.2853879,27.8173036 80.2853879,25.8292082 C80.2853879,23.8411127 79.1630759,22.7179991 77.5894342,22.7179991 C76.1168006,22.7179991 74.9968936,23.840311 74.9968936,25.8292082 C74.9968936,27.8181053 76.1168006,28.9396156 77.5894342,28.9396156 L77.5894342,28.9396156 Z"
                id="Shape"
              ></path>
              <path
                d="M90.5513358,20.2288715 C93.8693709,20.2288715 96.1508708,22.738842 96.1508708,25.8292082 L96.1508708,26.4512897 L87.8545804,26.4512897 C88.0830511,27.7988657 89.2029581,28.936409 90.5513358,28.936409 C91.484458,28.936409 92.0440107,28.6253683 92.4384232,28.3143275 C92.6387295,28.1369721 92.807115,27.926558 92.9362487,27.692246 L96.0466561,27.692246 C95.8262674,28.3760199 95.4741318,29.0100274 95.0101208,29.5584905 C94.1804116,30.4924144 92.8328356,31.4255366 90.5513358,31.4255366 C87.3158707,31.4255366 84.847586,28.936409 84.847586,25.8260016 C84.847586,22.7155941 87.3158707,20.2288715 90.5513358,20.2288715 Z M93.0364551,24.6884582 C92.829629,23.6511213 91.8957052,22.7179991 90.5513358,22.7179991 C89.2069664,22.7179991 88.2698359,23.6302784 87.9587951,24.6884582 L93.0364551,24.6884582 Z"
                id="Shape"
              ></path>
              <path
                d="M97.9129006,20.5383089 L100.816482,20.5383089 L100.816482,26.5506944 C100.816482,28.1267411 101.646191,28.9356074 102.993767,28.9356074 C104.341343,28.9356074 105.478887,27.7948574 105.478887,26.135439 L105.478887,20.5383089 L108.381666,20.5383089 L108.381666,31.1201074 L105.478887,31.1201074 L105.478887,29.6659118 L105.374672,29.6659118 C105.184482,30.0129621 104.924664,30.3169922 104.6115,30.5589514 C103.887312,31.1351919 102.986031,31.4430775 102.060645,31.4303465 C99.4681043,31.4303465 97.9129006,29.8951841 97.9129006,26.9707598 L97.9129006,20.5383089 Z"
                id="Path"
              ></path>
              <path
                d="M112.947873,27.6954526 C113.050293,27.9530839 113.205696,28.1863245 113.404012,28.3800629 C113.735896,28.7119466 114.29625,29.0438303 115.229373,29.0438303 C116.57775,29.0438303 116.993006,28.6494178 116.993006,28.0064934 C116.993006,27.239313 115.333587,27.0108423 113.736698,26.6589174 C112.098122,26.3061908 110.459547,25.4564403 110.459547,23.4434936 C110.459547,21.7848768 112.015552,20.2288715 114.919133,20.2288715 C117.138104,20.2288715 118.40311,21.1002665 119.087721,21.9508187 C119.483297,22.4486002 119.759954,23.0301558 119.896587,23.6511213 L116.993006,23.6511213 C116.90687,23.4535843 116.7795,23.2767433 116.619436,23.1324529 C116.308395,22.8839409 115.81057,22.614586 114.919133,22.614586 C113.860954,22.614586 113.467343,23.0290398 113.467343,23.4434936 C113.467343,24.2114756 115.105918,24.4391446 116.744494,24.7918713 C118.341383,25.1445979 120,25.9943484 120,28.0064934 C120,29.7484819 118.316532,31.4287432 115.230174,31.4287432 C112.969517,31.4287432 111.601098,30.5156623 110.874802,29.5616971 C110.44274,29.0079784 110.125753,28.3734595 109.942482,27.6954526 L112.947873,27.6954526 Z"
                id="Path"
              ></path>
              <path
                d="M12.0319859,27.2441907 C7.51668174,27.2563484 3.18413245,25.4617279 0,22.2602561 L2.05222759,20.2072269 C4.69851055,22.8556037 8.28889742,24.3436225 12.0327875,24.3436225 C15.7766777,24.3436225 19.3670645,22.8556037 22.0133475,20.2072269 L24.0663767,22.2602561 C20.8816066,25.4622458 16.5481054,27.2569019 12.0319859,27.2441907 Z"
                id="Path"
              ></path>
              <path
                d="M0.0104214682,0.517866806 L2.91400285,0.517866806 L2.91400285,5.80876606 L3.01741588,5.80876606 C3.24906607,5.49779894 3.52107665,5.21904875 3.82628214,4.97985851 C4.54643615,4.43005072 5.43029473,4.13787773 6.33625268,4.15014931 C9.03220635,4.15014931 11.2102932,6.32743452 11.2102932,9.74968435 C11.2102932,13.1719342 9.03220635,15.3492194 6.33625268,15.3492194 C5.43029473,15.361491 4.54643615,15.069318 3.82628214,14.5195102 C3.52087022,14.2802443 3.24883587,14.0012001 3.01741588,13.689801 L2.91400285,13.689801 L2.91400285,15.0381786 L0.0104214682,15.0381786 L0.0104214682,0.517866806 Z M5.60995651,12.8584885 C7.08259014,12.8584885 8.20249714,11.7361765 8.20249714,9.7472794 C8.20249714,7.75838227 7.08018518,6.6360703 5.60995651,6.6360703 C4.03390985,6.6360703 2.91400285,7.75838227 2.91400285,9.7472794 C2.91400285,11.7361765 4.03390985,12.8584885 5.60995651,12.8584885 Z"
                id="Shape"
              ></path>
              <path
                d="M17.7421488,4.14774435 C18.6481068,4.13547278 19.5319654,4.42764577 20.2521194,4.97745355 C20.5570803,5.21665548 20.8288242,5.49540872 21.060184,5.8063611 L21.1643987,5.8063611 L21.1643987,4.4587851 L24.0671784,4.4587851 L24.0671784,15.0357737 L21.1643987,15.0357737 L21.1643987,13.687396 L21.060184,13.687396 C20.8290548,13.9987805 20.557287,14.2778278 20.2521194,14.5171052 C19.5319654,15.066913 18.6481068,15.359086 17.7421488,15.3468144 C15.0461952,15.3468144 12.8681083,13.1695292 12.8681083,9.7472794 C12.8681083,6.32502956 15.0461952,4.14774435 17.7421488,4.14774435 Z M18.4676433,12.8584885 C20.0444917,12.8584885 21.1643987,11.7361765 21.1643987,9.7472794 C21.1643987,7.75838227 20.0420867,6.6360703 18.4676433,6.6360703 C16.9950097,6.6360703 15.8751027,7.75838227 15.8751027,9.7472794 C15.8751027,11.7361765 16.9950097,12.8584885 18.4676433,12.8584885 Z"
                id="Shape"
              ></path>
              <polygon
                id="Path"
                points="26.3486783 0.517866806 29.251458 0.517866806 29.251458 15.0357737 26.3486783 15.0357737"
              ></polygon>
              <path
                d="M30.8194881,4.4587851 L32.1670642,4.4587851 L32.1670642,2.19812814 L35.0706455,2.19812814 L35.0706455,4.4587851 L37.9301361,4.4587851 L37.9301361,6.94390444 L35.0738521,6.94390444 L35.0738521,11.2992765 C35.0738521,12.1287185 35.4885731,12.5434395 36.3180151,12.5434395 L37.9333427,12.5434395 L37.9333427,15.0325671 L35.7993467,15.0325671 C33.4978055,15.0325671 32.1702708,13.7050324 32.1702708,11.2992765 L32.1702708,6.94711105 L30.8194881,6.94711105 L30.8194881,4.4587851 Z"
                id="Path"
              ></path>
              <path
                d="M41.5022947,0 C42.435417,0 43.1617131,0.721486262 43.1617131,1.55520372 C43.1617131,2.38892118 42.435417,3.11040744 41.5022947,3.11040744 C40.5691725,3.11040744 39.8428763,2.38892118 39.8428763,1.55520372 C39.8428763,0.721486262 40.5691725,0 41.5022947,0 Z"
                id="Path"
              ></path>
              <path
                d="M50.2130389,4.14774435 C52.4736958,4.14774435 53.8421148,5.16423833 54.6092951,6.22161653 C55.0717786,6.84225221 55.4098698,7.54647283 55.6049462,8.29548871 L52.5979518,8.29548871 C52.4775349,7.99403328 52.3094496,7.71389104 52.1001263,7.46577951 C51.7065154,7.05132573 51.1461611,6.6360703 50.2130389,6.6360703 C48.7812895,6.6360703 47.5170852,7.88103493 47.5170852,9.7472794 C47.5170852,11.6135239 48.7812895,12.8584885 50.2130389,12.8584885 C51.1461611,12.8584885 51.7065154,12.4432331 52.1001263,12.0287793 C52.3092801,11.7805467 52.4773483,11.500433 52.5979518,11.1990701 L55.6049462,11.1990701 C55.4330193,11.9486286 55.1082583,12.6546427 54.650981,13.2729423 C53.8838006,14.3303205 52.535423,15.3468144 50.2130389,15.3468144 C46.9775738,15.3468144 44.5092891,12.8616951 44.5092891,9.7472794 C44.5092891,6.6328637 46.9775738,4.14774435 50.2130389,4.14774435 Z"
                id="Path"
              ></path>
              <rect
                id="Rectangle"
                x="40.050504"
                y="4.4587851"
                width="2.90358138"
                height="10.5769886"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BalticAmadeusLogo;
