const GuruPayLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="60px"
      height="46px"
      viewBox="0 0 60 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>F769B626-C34F-4EA2-840A-5296D494EC8C</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="1"
      >
        <g
          id="landing"
          transform="translate(-727, -2699)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-11" transform="translate(100, 2657)">
            <g id="Group-8" transform="translate(627, 0)">
              <g id="Group" transform="translate(0, 42)">
                <path
                  d="M20.2271018,30.170135 C19.4248506,30.1944619 18.7198304,30.6563664 18.3794073,31.3857134 L11.6938028,45.8995968 L14.6598446,45.8995968 L20.3486596,33.5737545 L27.0342641,45.8995968 L30.0974604,45.8995968 L22.1720271,31.2641556 C21.7586999,30.5591354 21.0536798,30.1458847 20.2271018,30.170135 Z"
                  id="Path"
                ></path>
                <polygon
                  id="Path"
                  points="40.5510518 30.1699055 36.0534885 36.0046053 31.920522 30.1699055 28.8329988 30.1699055 34.765006 38.5086663 34.765006 45.8992908 37.2690669 45.8992908 37.2690669 38.532993 43.7115558 30.2184828 43.638575 30.1699055"
                ></polygon>
                <path
                  d="M13.2739782,34.5949474 C13.1037666,32.0665597 10.8914751,30.170288 8.36308742,30.170288 L3.16047317,30.170288 L1.14262837,30.170288 L0,30.170288 L0,32.4798105 L1.14262837,32.4798105 L3.16047317,32.4798105 L8.43599151,32.4798105 C9.70022361,32.4798105 10.8428214,33.4036959 10.9643793,34.6678515 C11.1102639,36.1265456 9.96766618,37.3664508 8.533299,37.3664508 L1.87196774,37.3664508 C0.850897214,37.3664508 0,38.1930288 0,39.2383956 L0,45.7781155 L2.30957596,45.7781155 L2.30957596,39.6759733 L8.55754936,39.6759733 C11.2804755,39.6516465 13.4684401,37.3421239 13.2739782,34.5949474 Z"
                  id="Path"
                ></path>
                <path
                  d="M5.98058439,24.7974928 L8.14429862,24.7974928 C11.1102639,24.7974928 13.5170938,22.3906629 13.5170938,19.4246976 C13.5170938,17.3825413 11.839611,15.7050584 9.7974546,15.7050584 L6.75854691,15.7050584 L6.75854691,18.0146573 L9.7974546,18.0146573 C10.5754554,18.0146573 11.2074949,18.6466969 11.2074949,19.4246976 C11.2074949,21.1265073 9.82178147,22.4878939 8.14429862,22.4878939 L5.98058439,22.4878939 C3.93843567,22.4878939 2.2852644,20.8347379 2.2852644,18.7925816 L2.2852644,14.6110378 C2.2852644,12.7390931 3.79256628,11.2318218 5.66453404,11.2318218 L12.3501692,11.2318218 L12.3501692,8.92222288 L5.6888456,8.92222288 C2.55268399,8.92222288 0,11.474861 0,14.6110378 L0,18.8169084 C0,22.1232204 2.67424183,24.7974928 5.98058439,24.7974928 Z"
                  id="Path"
                ></path>
                <path
                  d="M22.4637966,24.7974928 L22.561104,24.7974928 L22.8771238,24.7974928 L22.9743548,24.7974928 C26.4022246,24.7974928 29.2223817,22.0016626 29.2223817,18.5737928 L29.2223817,8.92222288 L26.693994,8.92222288 L26.693994,18.5737928 C26.693994,20.6159491 25.040838,22.293432 22.9743548,22.293432 L22.8771238,22.293432 L22.561104,22.293432 L22.4637966,22.293432 C20.4216402,22.293432 18.7442338,20.640276 18.7442338,18.5737928 L18.7442338,8.92222288 L16.2158461,8.92222288 L16.2158461,18.5737928 C16.2158461,22.0259894 19.0115999,24.7974928 22.4637966,24.7974928 Z"
                  id="Path"
                ></path>
                <path
                  d="M57.4716123,8.92222288 L57.4716123,18.5737928 C57.4716123,20.6159491 55.8184561,22.293432 53.7519728,22.293432 L53.6547422,22.293432 L53.3387223,22.293432 L53.2414913,22.293432 C51.1993349,22.293432 49.5218522,20.640276 49.5218522,18.5737928 L49.5218522,8.92222288 L46.9934645,8.92222288 L46.9934645,18.5737928 C46.9934645,22.0016626 49.7892182,24.7974928 53.2414913,24.7974928 L53.3387223,24.7974928 L53.6547422,24.7974928 L53.7519728,24.7974928 C57.1799192,24.7974928 60,22.0016626 60,18.5737928 L60,8.92222288 L57.4716123,8.92222288 Z"
                  id="Path"
                ></path>
                <path
                  d="M40.016167,18.4036577 C42.7390929,18.4036577 44.9270578,16.0940588 44.7569225,13.3468822 C44.5867111,10.8184945 42.3744194,8.92222288 39.8460317,8.92222288 L39.1653384,8.92222288 L34.6191213,8.92222288 L32.6012153,8.92222288 L31.4586175,8.92222288 L31.4586175,11.2318218 L32.6012153,11.2318218 L34.6191213,11.2318218 L39.1653384,11.2318218 L39.8946092,11.2318218 C41.1588413,11.2318218 42.3014391,12.1556307 42.4229969,13.4197863 C42.5688815,14.8784804 41.4262837,16.1183857 39.9919167,16.1183857 L33.306312,16.1183857 C32.2851955,16.1183857 31.4342906,16.9449636 31.4342906,17.9903304 L31.4342906,24.5300503 L33.7438896,24.5300503 L33.7438896,18.4036577 L35.3727187,18.4036577 L41.4748608,24.5057999 L44.7325957,24.5057999 L38.6547804,18.4036577 L40.016167,18.4036577 Z"
                  id="Path"
                ></path>
                <polygon
                  id="Path"
                  points="14.8785569 0 0 0 0 2.21232969 14.8785569 2.21232969"
                ></polygon>
                <polygon
                  id="Path"
                  points="59.9761322 0 14.8299797 0 14.8299797 2.21232969 59.9761322 2.21232969"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GuruPayLogo;
