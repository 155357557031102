const ScanoramaLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="90px"
      height="32px"
      viewBox="0 0 90 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>E47B43FC-35EE-4269-9E53-0C2A7B3323E5</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="1"
      >
        <g
          id="landing"
          transform="translate(-1146, -3006)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-11" transform="translate(100, 2657)">
            <g id="Group-8-Copy-4" transform="translate(1046, 300)">
              <g id="Group" transform="translate(0, 49)">
                <path
                  d="M8.78714757,25.1460503 C8.78714757,29.1338583 7.20472441,31.6319533 4.41198882,31.6319533 C1.71196342,31.6294133 0.0152400305,29.5910592 0.0152400305,25.1676403 L0.0152400305,19.2468885 L2.6022352,19.2468885 L2.6022352,26.6560833 C2.6022352,28.9306579 2.77114554,30.5994412 4.41198882,30.5994412 C5.93599187,30.5994412 6.2001524,29.0652781 6.2001524,26.6560833 C6.2001524,23.3248666 5.54356109,21.3728727 2.93370587,16.004572 C0.706121412,11.4224028 0,9.20370841 0,6.30429261 C0,2.31648463 1.58242316,0 4.37515875,0 C7.07391415,0 8.77063754,2.0993142 8.77063754,5.84201168 L8.77063754,11.010922 L6.18364237,11.010922 L6.18364237,5.38735077 C6.18364237,3.11277623 6.01473203,1.03124206 4.37515875,1.03124206 C2.95275591,1.03124206 2.58572517,2.73050546 2.58572517,5.38735077 C2.58572517,9.74345949 4.49072898,12.9451359 5.53975108,15.00889 C6.95326391,17.7927356 8.78714757,21.9710439 8.78714757,25.1460503"
                  id="Path"
                ></path>
                <path
                  d="M56.0985522,31.2420625 C55.02413,25.8013716 54.7561595,24.6507493 53.7782576,19.8285497 C53.2504256,19.9220496 52.7153734,19.9687976 52.1793244,19.9682499 L52.1793244,31.2420625 L49.4653289,31.2420625 L49.4653289,0.518161036 L52.2250445,0.518161036 C55.7632715,0.518161036 57.7139954,2.59207518 57.7139954,6.8999238 L57.7139954,13.9420879 C57.7139954,16.7183134 56.3030226,18.8303277 54.8082296,19.476759 C56.6040132,27.9667259 57.2923546,31.2420625 57.2923546,31.2420625 L56.0985522,31.2420625 Z M52.1793244,1.54686309 L52.1793244,18.7769876 L52.3241046,18.7769876 C53.8392177,18.7769876 54.99111,17.2225044 54.99111,14.4589789 L54.99111,5.89535179 C54.99111,2.81432563 54.2291085,1.54686309 52.2593345,1.54686309 L52.1704343,1.54686309 L52.1793244,1.54686309 Z"
                  id="Shape"
                ></path>
                <path
                  d="M63.7782576,31.2420625 L66.3296927,31.2420625 L63.4086868,0.581661163 L61.1722123,0.581661163 L58.4201168,31.2255525 L59.6139192,31.2382525 L60.2565405,24.3624587 L63.1597663,24.3624587 L63.7782576,31.2420625 Z M60.3721107,23.1407163 L61.7373635,8.52425705 L63.0505461,23.1369063 L60.3721107,23.1407163 Z"
                  id="Shape"
                ></path>
                <path
                  d="M87.4485649,31.2420625 L90,31.2420625 L87.0840742,0.577851156 L84.8425197,0.577851156 L82.0904242,31.2255525 L83.2854966,31.2382525 L83.9281179,24.3624587 L86.8313437,24.3624587 L87.4485649,31.2420625 Z M84.0424181,23.1407163 L85.4076708,8.52425705 L86.7208534,23.1369063 L84.0424181,23.1407163 Z"
                  id="Shape"
                ></path>
                <path
                  d="M24.494539,31.2420625 L27.0510541,31.2420625 L24.1300483,0.577851156 L21.8884938,0.577851156 L19.1363983,31.2255525 L20.3302007,31.2382525 L20.9740919,24.3624587 L23.8760478,24.3624587 L24.494539,31.2420625 Z M21.0883922,23.1407163 L22.4536449,8.52425705 L23.7668275,23.1369063 L21.0883922,23.1407163 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="36.1353823 0.566421133 36.1353823 23.8277877 30.5956312 0.566421133 28.9446279 0.566421133 28.4518669 0.566421133 28.4086868 0.566421133 28.4086868 31.2382525 29.6380493 31.2382525 29.6380493 8.54457709 35.1968504 31.2382525 35.6756414 31.2382525 36.1874524 31.2382525 37.3685547 31.2382525 37.3685547 0.566421133"
                ></polygon>
                <polygon
                  id="Path"
                  points="79.1630683 0.566421133 78.4366269 0.566421133 78.1153162 0.566421133 74.3599187 23.2969266 70.2730505 0.566421133 69.5770892 0.566421133 69.2570485 0.566421133 67.7368555 0.566421133 67.7368555 31.2382525 68.8214376 31.2382525 68.8214376 8.54076708 73.0441961 31.2382525 74.1173482 31.2382525 73.7846076 31.2382525 74.5453391 31.2382525 78.1623063 8.79730759 78.1623063 31.2382525 80.6896114 31.2382525 80.6896114 0.566421133"
                ></polygon>
                <path
                  d="M47.7597155,5.91948184 C47.7597155,1.98247396 46.1836424,0.0977901956 43.4023368,0.0977901956 C40.7150114,0.0977901956 39.024638,2.18948438 39.024638,5.91948184 L39.024638,14.7396495 L39.024638,14.7396495 L39.024638,25.2006604 C39.024638,29.1376683 40.6019812,31.6408433 43.3820168,31.6408433 C46.0706121,31.6408433 47.7597155,28.9306579 47.7597155,25.2006604 L47.7597155,17.000254 L47.7597155,17.000254 L47.7597155,5.91948184 Z M45.0457201,25.8915418 C45.0457201,28.1559563 45.01651,30.501651 43.3820168,30.501651 C41.8580137,30.501651 41.7399035,28.3070866 41.7399035,25.8915418 L41.7399035,5.7505715 C41.7399035,3.48488697 41.7691135,1.13919228 43.4023368,1.13919228 C44.9263399,1.13919228 45.0457201,3.33375667 45.0457201,5.7505715 L45.0457201,25.8915418 L45.0457201,25.8915418 Z"
                  id="Shape"
                ></path>
                <path
                  d="M18.022606,10.991872 L18.022606,6.13792228 C18.022606,2.28600457 16.9278639,0.0863601727 14.1351283,0.0863601727 C11.4376429,0.0863601727 10.2286005,2.28600457 10.2286005,6.13792228 L10.2286005,25.5791212 C10.2286005,29.5275591 11.3233426,31.6294133 14.1148082,31.6294133 C16.8135636,31.6294133 18.022606,29.3230886 18.022606,25.5791212 L18.022606,19.2468885 L15.4368809,19.2468885 L15.4368809,25.6261113 C15.4368809,27.8994158 15.7556515,30.5981712 14.1148082,30.5981712 C12.5908052,30.5981712 12.8143256,28.0518161 12.8143256,25.6261113 L12.8143256,5.67945136 C12.8143256,2.7000254 12.8841758,1.11760224 14.1351283,1.11760224 C15.3594107,1.11760224 15.4368809,2.79146558 15.4368809,5.67945136 L15.4368809,10.991872 L18.022606,10.991872 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ScanoramaLogo;
