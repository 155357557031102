import { Routes, Route } from "react-router-dom";
import ContactsPage from "./Pages/ContactsPage/ContactsPage";
import ClientsPage from "./Pages/ClientsPage/ClientsPage";
import HomePage from "./Pages/HomePage/HomePage";
import ProjectsPage from "./Pages/ProjectsPage/ProjectsPage";
import ServicesPage from "./Pages/ServicesPage/ServicesPage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <div>
      <Routes>
        {/* <Route path="/projektai" element={<ProjectsPage />} /> */}
        <Route
          path="/services/software-development"
          element={
            <ServicesPage
              info={{
                heading: "Innovating Digital Solutions: Software Development",
                title:
                  "Software development enables businesses to create tailored solutions that streamline processes, enhance productivity, and drive innovation in the digital landscape.",
                description:
                  "Imas excels in software development within the web creation business, pioneering innovative solutions that redefine digital experiences. Our seasoned team meticulously crafts custom web applications and sites, leveraging state-of-the-art technologies and agile methodologies to deliver unparalleled results. From conceptualization to deployment, Imas prioritizes collaboration with clients, ensuring every aspect of their vision is realized with precision and efficiency. With a steadfast commitment to quality assurance and continuous refinement, Imas sets the standard for excellence in web development, empowering businesses to thrive in the digital realm.",
                experience:
                  "The Imas team provides tailored software development services that elevate your digital presence and drive success.",
                conclusion:
                  "Imas can offer scalable and adaptable software development solutions, catering to the specific requirements of both small startups and large enterprises, fostering growth and innovation across all business sizes.",
              }}
            />
          }
        />
        <Route
          path="/services/ui-ux"
          element={
            <ServicesPage
              info={{
                heading: "Optimizing UI/UX Design Solutions",
                title:
                  "UI/UX design solutions enhance user satisfaction, streamline navigation, and optimize usability for improved engagement.",
                description:
                  "At Imas, our UI/UX design solutions are meticulously crafted to ensure seamless user experiences across digital platforms. Through strategic design approaches, we enhance user satisfaction, streamline navigation, and optimize usability, ultimately fostering deeper engagement with your brand. Our team combines creativity with user-centric principles to deliver intuitive interfaces that resonate with your audience, driving higher conversion rates and fostering long-term customer loyalty. Whether it's web, mobile, or software interfaces, Imas is committed to delivering innovative design solutions that elevate your digital presence and set you apart in today's competitive landscape.",
                experience:
                  "The Imas team has extensive expertise in crafting intuitive and engaging user interfaces through cutting-edge UI/UX design solutions",
                conclusion:
                  "Our company can provide companies with innovative UI/UX design solutions to optimize their digital presence and enhance user satisfaction.",
              }}
            />
          }
        />
        <Route
          path="/services/software-maintenance"
          element={
            <ServicesPage
              info={{
                heading: "Ongoing software maintenance",
                title:
                  "Software maintenance prolongs software lifespan and adapts it to changing needs.",
                description:
                  "At Imas Company, we understand the critical importance of software maintenance in ensuring the longevity and effectiveness of your digital assets. Our comprehensive approach to maintenance involves ongoing updates, bug fixes, and optimizations to keep your software running smoothly and meeting the evolving needs of your business. With Imas Company's dedicated maintenance services, you can trust that your software will remain reliable, secure, and aligned with your strategic objectives, allowing you to focus on driving innovation and growth.",
                experience:
                  "Imas Company possesses extensive expertise in delivering tailored software maintenance solutions, ensuring optimal performance and longevity for our clients' digital assets.",
                conclusion:
                  "The Imas team specializes in providing comprehensive software maintenance services to help businesses ensure the ongoing reliability and performance of their digital solutions.",
              }}
            />
          }
        />
        <Route
          path="/services/it-consulting"
          element={
            <ServicesPage
              info={{
                heading: "IT Consulting Solutions",
                title:
                  "Technology Consulting Services: Transforming Your Business with Expert IT Solutions",
                description:
                  "Imas offers comprehensive IT consulting services tailored to meet the unique needs of your business. Our team of experienced professionals works closely with you to develop strategic technology solutions that drive innovation, enhance efficiency, and maximize your competitive edge in today's rapidly evolving digital landscape. From IT infrastructure optimization and cybersecurity to cloud migration and digital transformation initiatives, we leverage cutting-edge technologies and industry best practices to deliver measurable results that align with your organizational goals. With ImasTech Consulting as your trusted partner, you can navigate the complexities of IT with confidence, empowering your business to thrive in the digital age.",
                experience:
                  "Imas team can bring extensive experience in IT consulting, empowering businesses to leverage technology for growth and innovation.",
                conclusion:
                  "Our company can help other organizations with IT consulting by optimizing technology infrastructure, enhancing cybersecurity measures, implementing strategic digital transformations, and providing tailored solutions to meet specific business needs.",
              }}
            />
          }
        />
        <Route
          path="/services/mobile-applications"
          element={
            <ServicesPage
              info={{
                heading: "Unlocking Potential: The Mobile App Universe",
                title:
                  "AppSphere: Navigating the Diverse Landscape of Mobile Applications for Effortless Integration into Your Digital Lifestyle",
                description:
                  "Imas Innovations introduces a groundbreaking array of mobile applications designed to revolutionize your digital experience. From productivity tools to entertainment platforms, our meticulously crafted apps cater to diverse needs, seamlessly integrating into your daily routine. Dive into a world of endless possibilities with Imas' innovative solutions, empowering users to streamline tasks, stay organized, and unleash creativity on the go. Whether you seek efficient task management, immersive entertainment, or seamless communication, Imas' suite of mobile applications promises to enhance every aspect of your mobile experience. Discover the future of mobile technology with Imas Innovations today.",
                experience:
                  "With extensive experience in mobile application development, Imas team has consistently pioneered innovative solutions, leveraging emerging technologies to create seamless and intuitive user experiences.",
                conclusion:
                  "Imas offers comprehensive support to businesses seeking to elevate their digital presence through tailored mobile applications, leveraging our expertise to drive innovation and enhance user engagement.",
              }}
            />
          }
        />
        <Route
          path="/services/system-integration-solutions"
          element={
            <ServicesPage
              info={{
                heading: "Seamless Connectivity: System Integration Solutions",
                title:
                  "System integration solutions streamline processes, promote interoperability, and enable efficient data exchange, enhancing overall operational efficiency.",
                description:
                  "Unlocking efficiency and connectivity, Imas offers cutting-edge system integration solutions tailored to seamlessly merge disparate systems and processes. With Imas's expertise, businesses can optimize their operations by integrating various software, hardware, and networking components into a cohesive ecosystem. Imas's solutions enable smooth data exchange, enhance collaboration, and empower informed decision-making. By bridging the gaps between different technologies and platforms, IMAS drives efficiency, agility, and innovation, positioning businesses for sustained success in today's rapidly evolving digital landscape.",
                experience:
                  "With nearly a decade of expertise, the IMAS team can excel in seamlessly integrating diverse technologies to enhance operational efficiency and drive business growth.",
                conclusion:
                  "Imas provides expert assistance to companies seeking to streamline their operations through tailored system integration solutions, facilitating seamless connectivity and maximizing efficiency across their technological landscape.",
              }}
            />
          }
        />

        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/clients" element={<ClientsPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>

      <ScrollToTop />
    </div>
  );
}

export default App;
