import ServicesPageActivitiesComponent from "../../../components/ServicesPageActivitiesComponent/ServicesPageActivitiesComponent";
import styles from "./ServicesPageContent.module.scss";

const ServicesPageContent = ({ info }) => {
  return (
    <div className="container">
      <div
        className={[
          styles.heading,
          "col-8 col-md-8 col-lg-6 col-xl-5 mb-6 mb-lg-12 offset-1",
        ].join(" ")}
      >
        {info.heading}
      </div>
      <div
        className={[
          styles.mediumTextSize,
          "col-10 col-lg-8 mb-4 offset-1",
        ].join(" ")}
      >
        {info.title}
      </div>
      <div
        className={[
          styles.smallTextSize,
          "col-10 col-lg-8 mb-12 offset-1",
        ].join(" ")}
      >
        {info.description}
      </div>
      <div
        className={[
          styles.mediumTextSize,
          "col-10 col-lg-8 mb-4 offset-1 pe-2",
        ].join(" ")}
      >
        {info.experience}
      </div>
      <div
        className={[
          styles.smallTextSize,
          "col-10 col-lg-8 mb-12 offset-1",
        ].join(" ")}
      >
        {info.conclusion}
      </div>
      <ServicesPageActivitiesComponent />
    </div>
  );
};
export default ServicesPageContent;
