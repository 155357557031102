const KitronLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="80px"
      height="33px"
      viewBox="0 0 80 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>9AB1D34D-C5A0-472C-9156-0FF5F067BEE4</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="1"
      >
        <g
          id="landing"
          transform="translate(-100, -3006)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-11" transform="translate(100, 2657)">
            <g id="Group-8" transform="translate(0, 300)">
              <g id="Group" transform="translate(0, 49)">
                <path
                  d="M19.3521093,0 L19.352,0.003 L18.5521093,0.00491773562 C18.6250557,6.82581705 15.1613306,12.8877458 9.9001731,16.4383509 C15.22772,19.9209274 18.5693214,26.0140018 18.5807961,32.8496543 L12.8025051,32.8496543 C12.79185,25.6582856 7.19300797,19.6709425 0,19.6832368 L0,13.2385443 C7.18890986,13.1582213 12.8844673,7.19218835 12.8049639,0 L19.3521093,0 Z"
                  id="Combined-Shape"
                ></path>
                <path
                  d="M13.7122862,16.4383509 C19.1637341,12.7566271 22.39523,6.57768898 22.3093077,0 L19.3521093,0.00491773562 C19.4250557,6.82581705 15.9613306,12.8877458 10.7001731,16.4383509 C16.02772,19.9209274 19.3693214,26.0140018 19.3807961,32.8496543 L22.3666812,32.8496543 C22.3560261,26.0131822 19.0349154,19.921747 13.7122862,16.4383509 Z"
                  id="Path"
                ></path>
                <polygon
                  id="Path"
                  points="34.1503954 8.17327661 32.9775155 15.5752883 33.0209555 15.5752883 36.9158021 8.17327661 40.254125 8.17327661 35.9904482 15.8187163 38.0255711 24.7353906 34.5782384 24.7353906 32.9062083 16.3006543 32.8627683 16.3006543 31.5267835 24.7353906 28.2655051 24.7353906 30.8874778 8.17327661"
                ></polygon>
                <path
                  d="M45.0554742,13.6516341 L43.2998426,24.7353906 L40.1967514,24.7353906 L41.9515634,13.6516341 L45.0554742,13.6516341 Z M42.0679498,10.3272448 C42.2449883,9.20927958 43.2760735,8.37572339 44.3932191,8.37572339 C45.468564,8.37572339 46.1529488,9.31910901 45.9841066,10.3723241 C45.8152643,11.4255391 44.7759829,12.327124 43.7022772,12.327124 C42.6285716,12.327124 41.8925505,11.4255391 42.0679498,10.3297037 L42.0679498,10.3272448 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="52.2230738 10.578869 51.7353984 13.6524537 53.0090919 13.6524537 52.6066572 16.1990211 51.3329637 16.1990211 49.9797668 24.7353906 46.8766756 24.7353906 48.2290529 16.1990211 47.1520688 16.1990211 47.5561427 13.6524537 48.6314876 13.6524537 49.119163 10.578869"
                ></polygon>
                <path
                  d="M58.6276049,13.6524537 L58.4161422,14.9908974 L58.4595822,14.9908974 C59.183309,14.025382 60.038995,13.3459149 61.2635112,13.2344462 L60.7856712,16.2432808 C60.6291398,16.2162954 60.4707504,16.2014978 60.3119293,16.1990211 C58.2464804,16.1990211 58.0096094,17.5604143 57.7497891,19.2062165 L56.8719733,24.7362102 L53.7672428,24.7362102 L55.5236941,13.6524537 L58.6276049,13.6524537 Z"
                  id="Path"
                ></path>
                <path
                  d="M66.0615819,16.1998408 C67.1426641,16.1998408 66.8123562,18.5562558 66.7115426,19.1955614 C66.610729,19.834867 66.1927215,22.191282 65.1132785,22.191282 C64.0338356,22.191282 64.3641435,19.8340474 64.4649571,19.1955614 C64.5657706,18.5570754 64.9829585,16.1998408 66.0615819,16.1998408 L66.0615819,16.1998408 Z M66.5033585,13.4213201 C63.1773299,13.4213201 61.8110191,16.3539298 61.3602266,19.1955614 C60.9094342,22.037193 61.3495715,24.962426 64.6747804,24.962426 C67.9999893,24.962426 69.3663002,22.0355537 69.8154534,19.1955614 C70.2646066,16.3555691 69.8269281,13.4213201 66.5041781,13.4213201 L66.5033585,13.4213201 Z"
                  id="Shape"
                ></path>
                <path
                  d="M75.3995422,15.3687434 L75.4438018,15.3687434 C76.0224554,14.3122499 77.0945218,13.4336145 78.3026455,13.4336145 C80.5910318,13.4336145 79.9886092,15.9826408 79.736985,17.5858226 L78.6018077,24.7353906 L75.5011754,24.7353906 L76.4634124,18.6595282 C76.5519316,18.0857924 77.015838,16.5530981 76.0355694,16.5530981 C75.3405294,16.5530981 75.0954622,17.2768249 75.0102215,17.825972 L73.9143861,24.7353906 L70.815393,24.7353906 L72.5693854,13.6532733 L75.6724765,13.6532733 L75.4011815,15.3687434 L75.3995422,15.3687434 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default KitronLogo;
