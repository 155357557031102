export const routesList = [
  "/contacts",
  "/clients",
  "/services/system-integration-solutions",
  "/services/mobile-applications",
  "/services/it-consulting",
  "/services/software-maintenance",
  "/services/ui-ux",
  "/services/software-development",
];
