import ZmonesLogo from "../components/ClientsLogosComponents/ZmonesLogo";
import BnsLogo from "../components/ClientsLogosComponents/BnsLogo";
import KiloHealthLogo from "../components/ClientsLogosComponents/KiloHealthLogo";
import GuruPayLogo from "../components/ClientsLogosComponents/GuruPayLogo";
import LuminorLogo from "../components/ClientsLogosComponents/LuminorLogo";
import TeliaLogo from "../components/ClientsLogosComponents/TeliaLogo";
import AxiomaLogo from "../components/ClientsLogosComponents/AxiomaLogo";
import LitexpoLogo from "../components/ClientsLogosComponents/LitexpoLogo";
import GjensidigeLogo from "../components/ClientsLogosComponents/GjensidigeLogo";
import JuodeliaiLogo from "../components/ClientsLogosComponents/JuodeliaiLogo";
import BalticToursLogo from "../components/ClientsLogosComponents/BalticToursLogo";
import BeKreditoLogo from "../components/ClientsLogosComponents/BeKreditoLogo";
import KitronLogo from "../components/ClientsLogosComponents/KitronLogo";
import SkrenduLogo from "../components/ClientsLogosComponents/SkrenduLogo";
import CitadeleLogo from "../components/ClientsLogosComponents/CitadeleLogo";
import AutobilisLogo from "../components/ClientsLogosComponents/AutobilisLogo";
import UnlokkLogo from "../components/ClientsLogosComponents/UnlokkLogo";
import ScanoramaLogo from "../components/ClientsLogosComponents/ScanoramaLogo";
import TakeOnlineLogo from "../components/ClientsLogosComponents/TakeOnlineLogo";
import PenkiolikaMinLogo from "../components/ClientsLogosComponents/PenkiolikaMinLogo";
import BalticAmadeusLogo from "../components/ClientsLogosComponents/BalticAmadeusLogo";
import OpalTransferLogo from "../components/ClientsLogosComponents/OpalTransferLogo";
import HuracanCoffeeLogo from "../components/ClientsLogosComponents/HuracanCoffeeLogo";
import SebBankLogo from "../components/ClientsLogosComponents/SebBankLogo";
import LavangoLogo from "../components/ClientsLogosComponents/LavangoLogo";
import PillarLogo from "../components/ClientsLogosComponents/PillarLogo";
import IfDraudimasLogo from "../components/ClientsLogosComponents/IfDraudimasLogo";
import MiestoZirafosLogo from "../components/ClientsLogosComponents/MiestoZirafosLogo";
import KampasLogo from "../components/ClientsLogosComponents/KampasLogo";
import MeniuLogo from "../components/ClientsLogosComponents/MeniuLogo";
import EmpRecyclingLogo from "../components/ClientsLogosComponents/EmpRecyclingLogo";
import RegistruCentrasLogo from "../components/ClientsLogosComponents/RegistruCentrasLogo";
import AviaSolutionsGroupLogo from "../components/ClientsLogosComponents/AviaSolutionsGroupLogo";
import BalticAssistLogo from "../components/ClientsLogosComponents/BalticAssistLogo";
import BudoraLogo from "../components/ClientsLogosComponents/BudoraLogo";
import EndocaLogo from "../components/ClientsLogosComponents/EndocaLogo";
import MarketRatsLogo from "../components/ClientsLogosComponents/MarketRatsLogo";
import HybridLabLogo from "../components/ClientsLogosComponents/HybridLabLogo";
import TanagraLogo from "../components/ClientsLogosComponents/TanagraLogo";

export const clientsList = [
  {
    logo: <ZmonesLogo />,
    alt: "Zmones.lt",
    url: "https://zmones.lt",
  },
  {
    logo: <BnsLogo />,
    alt: "Bns",
    url: "https://bns.lt",
  },
  {
    logo: <KiloHealthLogo />,
    alt: "KiloHealth",
    url: "https://kilo.health/",
  },
  {
    logo: <GuruPayLogo />,
    alt: "GuruPay",
    url: "https://gurupay.eu",
  },
  {
    logo: <LuminorLogo />,
    alt: "Luminor",
    url: "https://luminor.lt",
  },
  {
    logo: <TeliaLogo />,
    alt: "Telia",
    url: "https://telia.lt",
  },
  {
    logo: <AxiomaLogo />,
    alt: "Axioma",
    url: "https://axiomametering.com",
  },
  {
    logo: <LitexpoLogo />,
    alt: "Litexpo",
    url: "https://litexpo.lt",
  },
  {
    logo: <GjensidigeLogo />,
    alt: "Gjensidige",
    url: "https://gjensidige.lt",
  },
  {
    logo: <JuodeliaiLogo />,
    alt: "Juodeliai",
    url: "https://juodeliai.com",
  },
  {
    logo: <BalticToursLogo />,
    alt: "BalticTours",
    url: "https://baltictours.lt",
  },
  {
    logo: <BeKreditoLogo />,
    alt: "BeKredito",
    url: "https://bekredito.lt",
  },
  {
    logo: <KitronLogo />,
    alt: "Kitron",
    url: "https://kitron.com",
  },
  {
    logo: <SkrenduLogo />,
    alt: "Skrendu.lt",
    url: "https://skrendu.lt",
  },
  {
    logo: <CitadeleLogo />,
    alt: "Citadele",
    url: "https://citadele.lt",
  },
  {
    logo: <AutobilisLogo />,
    alt: "Autobilis",
    url: "https://autobilis.lt",
  },
  {
    logo: <UnlokkLogo />,
    alt: "Unlokk",
    url: "https://unlokk.lt",
  },
  {
    logo: <ScanoramaLogo />,
    alt: "Scanorama",
    url: "https://scanorama.lt",
  },
  {
    logo: <TakeOnlineLogo />,
    alt: "TakeOnline",
    url: "https://takeonline.eu",
  },
  {
    logo: <PenkiolikaMinLogo />,
    alt: "15min",
    url: "https://15min.lt",
  },
  {
    logo: <BalticAmadeusLogo />,
    alt: "BalticAmadeus",
    url: "https://ba.lt",
  },
  {
    logo: <OpalTransferLogo />,
    alt: "OpalTransfer",
    url: "https://opaltransfer.com",
  },
  {
    logo: <HuracanCoffeeLogo />,
    alt: "HuracanCoffee",
    url: "https://huracan.lt",
  },
  {
    logo: <SebBankLogo />,
    alt: "SebBank",
    url: "https://seb.lt",
  },
  {
    logo: <LavangoLogo />,
    alt: "Lavango",
    url: "https://lavango.eu",
  },
  {
    logo: <PillarLogo />,
    alt: "Pillar",
    url: "https://pillar.fi",
  },
  {
    logo: <IfDraudimasLogo />,
    alt: "IfDraudimas",
    url: "https://if.lt",
  },
  {
    logo: <MiestoZirafosLogo />,
    alt: "MiestoZirafos",
    url: "https://mzirafos.lt",
  },
  {
    logo: <KampasLogo />,
    alt: "Kampas.lt",
    url: "https://kampas.lt",
  },
  {
    logo: <AviaSolutionsGroupLogo />,
    alt: "AviaSolutionsGroup",
    url: "https://aviasg.com",
  },
  {
    logo: <MeniuLogo />,
    alt: "Meniu.lt",
    url: "https://meniu.lt",
  },
  {
    logo: <EmpRecyclingLogo />,
    alt: "EmpRecycling",
    url: "https://emp.lt",
  },
  {
    logo: <RegistruCentrasLogo />,
    alt: "RegistruCentras",
    url: "https://registrucentras.lt",
  },
  {
    logo: <BalticAssistLogo />,
    alt: "BalticAssist",
    url: "https://balticassist.com",
  },
  {
    logo: <BudoraLogo />,
    alt: "Budora",
    url: "https://budora.lt",
  },
  {
    logo: <EndocaLogo />,
    alt: "Endoca",
    url: "https://endoca.com",
  },
  // {
  //   logo: <MarketRatsLogo />,
  //   alt: "MarketRats",
  //   url: "https://marketrats.lt",
  // },
  {
    logo: <HybridLabLogo />,
    alt: "HybridLab",
    url: "https://hybridlab.com",
  },
  {
    logo: <TanagraLogo />,
    alt: "Tanagra",
    url: "https://tanagra.lt",
  },
];
