export const mediaList = [
  {
    media: "LinkedIn",
    url: "https://www.linkedin.com/company/imas-lt/",
  },
  {
    media: "Instagram",
    url: "https://www.instagram.com/imas_lt/",
  },
  {
    media: "Facebook",
    url: "https://www.facebook.com/imasapps/",
  },
];
