import styles from "../Pages/ContactsPage/ContactsPageContent/ContactsPageContent.module.scss";
import vilnius from "../assets/vilnius.png";
import klaipeda from "../assets/klaipeda.png";

export const officesData = [
  {
    city: "Vilnius",
    street: "A. Smetonos st. 5",
    tel: "+370 662 400 99",
    directions: "https://maps.app.goo.gl/fGLnbpSS16RKymuS7",
    activeClass: styles.capitalOffice,
    backgroundImage: vilnius,
  },
  {
    city: "Klaipėda",
    street: "Liepų st. 83A",
    tel: "+370 662 400 99",
    directions: "https://maps.app.goo.gl/zred9weeH49qSAgu5",
    activeClass: styles.portOffice,
    backgroundImage: klaipeda,
  },
];
