const PillarLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="100px"
      height="30px"
      viewBox="0 0 100 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>16AD2129-8983-47E6-BEA1-385D369006B8</title>
      <g
        id="desktop"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="clients-modal"
          transform="translate(-624, -1340)"
          fill={fill}
          fill-rule="nonzero"
        >
          <g id="Group-8-Copy" transform="translate(624, 1290)">
            <g id="Group" transform="translate(0, 50)">
              <path
                d="M30.831626,5.44552846 L36.8178862,5.44552846 C40.1091057,5.44552846 42.3645528,7.60227642 42.3645528,10.9020325 C42.3645528,14.1769919 40.0073984,16.4356098 36.8178862,16.4356098 L32.9304878,16.4356098 L32.9304878,23.6329268 L30.831626,23.6329268 L30.831626,5.44552846 Z M36.6365854,14.5653659 C38.8123577,14.5653659 40.2134959,13.0064228 40.2134959,10.9020325 C40.2134959,8.82243902 38.815122,7.31577236 36.6365854,7.31577236 L32.9304878,7.31577236 L32.9304878,14.5653659 L36.6365854,14.5653659 Z"
                id="Shape"
              ></path>
              <path
                d="M47.7504878,11.8631707 L45.2120325,11.8631707 L45.2120325,10.1223577 L49.8,10.1223577 L49.8,23.6327642 L47.753252,23.6327642 L47.753252,11.8631707 L47.7504878,11.8631707 Z M47.2862602,6.17260163 C47.2862602,5.34073171 47.9071545,4.69073171 48.7642276,4.69073171 C49.6460976,4.69073171 50.2669919,5.34073171 50.2669919,6.17260163 C50.2669919,7.02918699 49.6460976,7.67926829 48.7642276,7.67926829 C47.9071545,7.67926829 47.2862602,7.02918699 47.2862602,6.17260163 Z"
                id="Shape"
              ></path>
              <polygon
                id="Path"
                points="54.5426016 21.8645528 57.265122 21.8645528 57.265122 5.91105691 54.5426016 5.91105691 54.5426016 4.14544715 59.3118699 4.14544715 59.3118699 21.8645528 62.0343902 21.8645528 62.0343902 23.6329268 54.5453659 23.6329268"
              ></polygon>
              <polygon
                id="Path"
                points="64.8818699 21.8645528 67.6043902 21.8645528 67.6043902 5.91105691 64.8818699 5.91105691 64.8818699 4.14544715 69.6511382 4.14544715 69.6511382 21.8645528 72.3736585 21.8645528 72.3736585 23.6329268 64.8846341 23.6329268 64.8846341 21.8645528"
              ></polygon>
              <path
                d="M74.5723577,16.9038211 C74.5723577,12.7474797 77.1626016,9.78373984 80.997561,9.78373984 C83.797561,9.78373984 85.195935,11.601626 85.5861789,12.3039837 L85.7422764,12.3039837 L85.7422764,10.1225203 L87.8162602,10.1225203 L87.8162602,21.192439 C87.8162602,21.608374 88.0747967,21.8149593 88.4894309,21.8149593 L89.1105691,21.8149593 L89.1105691,23.6328455 L87.7894309,23.6328455 C86.6739837,23.6328455 85.8439024,22.8010569 85.8195122,21.6331707 L85.8195122,21.5037398 L85.6626016,21.5037398 C85.3252033,22.0491057 84.0829268,23.971626 81.1544715,23.971626 C77.2943089,23.9689431 74.5723577,21.0354472 74.5723577,16.9038211 Z M85.8195122,16.9286179 C85.8195122,13.5765041 83.7455285,11.6539837 81.2317073,11.6539837 C78.5886179,11.6539837 76.6707317,13.8354472 76.6707317,16.9286179 C76.6707317,20.0713821 78.6658537,22.1261789 81.2585366,22.1261789 C84.0829268,22.1261789 85.8195122,19.7601626 85.8195122,16.9286179 Z"
                id="Shape"
              ></path>
              <path
                d="M93.2609756,10.1225203 L95.2829268,10.1225203 L95.2829268,12.3839024 L95.4398374,12.3839024 C95.8268293,11.240813 97.1512195,9.78650407 99.1178862,9.78650407 L100,9.78650407 L100,11.8385366 L98.7829268,11.8385366 C96.3211382,11.8385366 95.3105691,13.8382114 95.3105691,16.410813 L95.3105691,23.6329268 L93.2634146,23.6329268 L93.2634146,10.1225203 L93.2609756,10.1225203 Z"
                id="Path"
              ></path>
              <polygon
                id="Path"
                points="5.4395935 29.0865854 0 23.6328455 0 18.1791057 14.5055285 18.1791057 14.5055285 0 19.945122 5.45373984 19.945122 23.6328455 5.4395935 23.6328455"
              ></polygon>
              <polygon
                id="Path"
                points="7.25276423 3.63593496 0 3.63593496 0 10.907561 7.25276423 10.907561"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PillarLogo;
