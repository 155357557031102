import { Helmet } from "react-helmet-async";
import Header from "../../components/Header/Header";
import ClientsPageClientsList from "./ClientsPageClientsList/ClientsPageClientsList";
import ClientsPageContent from "./ClientsPageContent/ClientsPageContent";
import ClientsPageFooter from "./ClientsPageFooter/ClientsPageFooter";

const ClientsPage = () => {
  return (
    <>
      <Helmet>
        <title>Clients - Imas.lt</title>
        <meta
          name="description"
          content="Explore some of the amazing clients we've worked with. From websites to design projects, see how we've helped businesses like yours succeed."
        ></meta>
      </Helmet>

      <div className="bg-white text-black">
        <Header />
        <ClientsPageContent />
        <ClientsPageClientsList />
        <ClientsPageFooter />
      </div>
    </>
  );
};

export default ClientsPage;
