const ZmonesLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="100px"
      height="26px"
      viewBox="0 0 100 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>A2681BA8-B156-4104-B4D5-65D8FC7CC3FB</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="1"
      >
        <g
          id="landing"
          transform="translate(-100, -2709)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-11" transform="translate(100, 2657)">
            <g id="Group-8-Copy" transform="translate(0, 0)">
              <g id="Group" transform="translate(0, 52)">
                <g>
                  <polygon
                    id="Path"
                    points="5.82655827 3.638776 8.19783198 0.499661259 6.02981032 0.16090787 4.56187894 1.76434057 3.07136406 0.16090787 0.880758809 0.499661259 3.27461608 3.638776"
                  ></polygon>
                  <polygon
                    id="Path"
                    points="0.519421862 7.6134824 5.21680216 7.6134824 0 21.9540425 0 24.641486 8.49141827 24.641486 8.49141827 21.9540425 3.45528456 21.9540425 8.69467029 7.6134824 8.69467029 4.92603886 0.519421862 4.92603886"
                  ></polygon>
                  <polygon
                    id="Path"
                    points="16.0117435 16.03715 13.9114724 4.92603886 9.89159891 4.92603886 9.89159891 24.641486 12.6919603 24.641486 12.6919603 12.7399504 15.2213189 24.641486 16.7570009 24.641486 19.3089431 12.7399504 19.3089431 24.641486 22.1093045 24.641486 22.1093045 4.92603886 18.0894309 4.92603886"
                  ></polygon>
                  <polygon
                    id="Path"
                    points="52.0776875 16.7598239 48.5094851 4.92603886 45.2348692 4.92603886 45.2348692 24.641486 48.0352305 24.641486 48.0352305 12.7851175 51.9421862 24.641486 54.8554652 24.641486 54.8554652 4.92603886 52.0776875 4.92603886"
                  ></polygon>
                  <polygon
                    id="Path"
                    points="56.300813 24.641486 64.1598917 24.641486 64.1598917 21.9540425 59.5528457 21.9540425 59.5528457 16.1726513 62.8500452 16.1726513 62.8500452 13.5077913 59.5528457 13.5077913 59.5528457 7.6134824 64.1598917 7.6134824 64.1598917 4.92603886 56.300813 4.92603886"
                  ></polygon>
                  <path
                    d="M33.6495032,4.65503615 C28.0487805,4.65503615 23.5094851,9.19433155 23.5094851,14.7950542 C23.5094851,20.3957769 28.0487805,24.9350723 33.6495032,24.9350723 C39.2502258,24.9350723 43.7895214,20.3957769 43.7895214,14.7950542 C43.7895214,9.19433155 39.2502258,4.65503615 33.6495032,4.65503615 Z M33.6495032,21.7507905 C29.8102981,21.7507905 26.693767,18.6342593 26.693767,14.7950542 C26.693767,10.9558492 29.8102981,7.83931799 33.6495032,7.83931799 C37.4887082,7.83931799 40.6052395,10.9558492 40.6052395,14.7950542 C40.6052395,18.6342593 37.4887082,21.7507905 33.6495032,21.7507905 Z"
                    id="Shape"
                  ></path>
                  <circle
                    id="Oval"
                    transform="translate(60.2324, 1.9433) rotate(-9.2184) translate(-60.2324, -1.9433)"
                    cx="60.2324412"
                    cy="1.94325882"
                    r="1.69379273"
                  ></circle>
                  <path
                    d="M72.0415538,14.3659666 C72.0415538,14.3659666 69.421861,11.5656053 69.2186089,11.3397697 C68.6540198,10.6622629 68.3830173,10.0073397 68.3830173,9.19433155 L68.3830173,9.05883018 C68.3830173,7.95223579 69.2863597,7.41023038 69.9638664,7.41023038 C70.5284554,7.41023038 71.1382114,7.72640021 71.4092142,8.4490741 C71.4995483,8.69749325 71.5447155,9.05883018 71.5447155,9.57825205 C71.5447155,10.0976739 71.5447155,10.142841 71.5447155,10.142841 L74.5934961,10.142841 L74.5934961,9.35241646 C74.5934961,6.48430445 72.6061428,4.65503615 69.9412828,4.65503615 C67.5925926,4.65503615 65.2664859,6.55205512 65.2664859,9.01366307 L65.2664859,9.42016714 C65.2664859,11.3171861 66.034327,12.604449 67.3893405,14.0723803 C67.6603434,14.3659666 69.4670282,16.2855691 69.4670282,16.2855691 C69.4670282,16.2855691 70.3252034,17.2114951 70.4607046,17.3469964 C71.1833786,18.1825881 71.4995483,18.9730127 71.4995483,19.9441057 L71.4995483,20.4183605 C71.4995483,21.5475384 70.8220416,22.1798781 69.98645,22.1798781 C69.625113,22.1798781 69.3089433,22.0669603 69.0379404,21.8637082 C68.8572719,21.7282069 68.699187,21.5475384 68.5862693,21.3217028 C68.4281845,21.0281166 68.3378501,20.5764454 68.3378501,19.9666893 C68.3378501,19.4472674 68.3378501,18.9278456 68.3378501,18.9278456 L65.2439026,18.9278456 L65.2439026,19.7182701 C65.2439026,19.7182701 65.2439026,20.5764454 65.3342367,21.1636179 C65.3568203,21.3668699 65.4245711,21.570122 65.469738,21.7507905 C66.0569106,23.6026423 67.682927,24.9124887 69.9412828,24.9124887 C72.6738936,24.9124887 74.751581,22.9703027 74.751581,20.2602756 L74.751581,19.8086044 C74.7289974,17.6405827 73.8482386,16.1952349 72.0415538,14.3659666 Z"
                    id="Path"
                  ></path>
                </g>
                <circle
                  id="Oval"
                  transform="translate(78.4104, 23.2416) rotate(-9.2184) translate(-78.4104, -23.2416)"
                  cx="78.410402"
                  cy="23.2415719"
                  r="1.69379273"
                ></circle>
                <polygon
                  id="Path"
                  points="82.633243 24.6866532 90.7407409 24.6866532 90.7407409 21.976626 85.9981933 21.976626 85.9981933 16.1952349 85.9981933 16.1952349 85.9981933 13.5077913 85.9981933 13.5077913 85.9981933 7.63606597 85.9981933 7.63606597 85.97561 4.92603886 82.633243 4.92603886"
                ></polygon>
                <polygon
                  id="Path"
                  points="91.1472448 4.92603886 100 4.92603886 100 7.63606597 97.2448059 7.63606597 97.2448059 24.6866532 93.8798556 24.6866532 93.8798556 7.63606597 91.1246612 7.63606597 91.1246612 4.92603886"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ZmonesLogo;
