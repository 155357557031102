export const activitiesList = [
  {
    name: "Front-End",
    technologies: [
      "HTML5, CSS3",
      "Bootstrap, Foundation",
      "Sass, Less, Gulp",
      "AngularJS",
      "JavaScript",
      "Avocode",
    ],
  },
  {
    name: "Back-End",
    technologies: ["PHP", "Symfony", "Angular", "Laravel", "Java", "MySQL"],
  },
  {
    name: "Management",
    technologies: ["Trello", "Jira", "Toggl", "Slack", "Bitbucket"],
  },
  {
    name: "Design",
    technologies: [
      "Figma",
      "Sketch",
      "InVision",
      "Adobe XD",
      "Adobe Illustrator",
    ],
  },
];
