export const servicesRoutesList = [
  {
    route: "software-development",
    name: "Software development",
  },
  {
    route: "system-integration-solutions",
    name: "System integration solutions",
  },
  {
    route: "software-maintenance",
    name: "Software maintenance",
  },
  {
    route: "ui-ux",
    name: "User interfaces UI / UX design solutions",
  },
  {
    route: "it-consulting",
    name: "IT consulting",
  },
  {
    route: "mobile-applications",
    name: "Mobile applications",
  },
];
