import { Helmet } from "react-helmet-async";
import Header from "../../components/Header/Header";
import ContactsPageContent from "./ContactsPageContent/ContactsPageContent";

const ContactsPage = () => {
  return (
    <>
      <Helmet>
        <title>Contacts - Imas.lt</title>
        <meta
          name="description"
          content="Get in touch with us at Imas.lt. Whether you have questions, feedback, or want to discuss a potential project, we're here to help!"
        ></meta>
      </Helmet>
      <div className="bg-white text-black">
        <Header />
        <ContactsPageContent />
      </div>
    </>
  );
};

export default ContactsPage;
