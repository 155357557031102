const PenkiolikaMinLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="46px"
      height="46px"
      viewBox="0 0 46 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>8566B41F-1F6F-4F64-8F63-DCF47BE1C45F</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="clients-modal" transform="translate(-415, -1182)" fill={fill}>
          <g id="15min_logo_spalvotas_CMYK" transform="translate(415, 1182)">
            <path
              d="M46,0 L46,46 L0,46 L0,0 L46,0 Z M37.0623725,11.042156 L22.153821,11.042156 L20.5528332,24.349797 L24.7553682,24.5498625 C25.2220332,23.4158488 26.7564097,22.3486782 28.8909828,22.3486782 C31.1253955,22.3486782 33.3601173,23.9829716 33.3601173,27.2847867 C33.3601173,30.3530761 31.5258356,32.1876669 28.9908997,32.1876669 C26.0225271,32.1876669 24.5886083,30.119705 24.3216997,27.6183065 L19.9859423,27.6183065 C20.252619,31.787304 23.25422,35.6562419 28.9242884,35.6562419 C33.8937026,35.6562419 38.0293172,32.5877206 38.0293172,27.2512493 C38.0293172,21.6816386 34.1939168,18.7465716 30.0583021,18.7465716 C27.8235804,18.7465716 26.25613,19.3467681 25.2556479,20.0806508 L25.9226102,14.9109393 L37.0623725,14.9109393 L37.0623725,11.042156 Z M16.4336782,11.0421946 L12.9315657,11.0421946 C11.7642464,13.6770474 10.129953,13.9103413 6.6280724,14.0104899 L6.6280724,17.1456224 L11.8310895,17.1456224 L11.8310895,35.2226121 L16.4336782,35.2226121 L16.4336782,11.0421946 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PenkiolikaMinLogo;
