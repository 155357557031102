import styles from "./Clients.module.scss";
import { Link } from "react-router-dom";
import { clientsList } from "../../Constants/ClientsList";
import Cursor from "../Cursor/Cursor";
import { useState } from "react";

const Clients = () => {
  const displayedClients = clientsList.slice(0, 18);

  const [showCursor, setShowCursor] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const handleLinkMouseEnter = () => {
    setShowCursor(true);
    setOpacity(1);
  };

  const handleLinkMouseLeave = () => {
    setShowCursor(false);
    setOpacity(0);
  };
  return (
    <div className={styles.clients}>
      <div className="container">
        <div className="row mb-0 mb-lg-8">
          {displayedClients.map((client, index) => (
            <div
              key={index}
              className={[
                styles.client,
                "col-6 col-sm-4 col-md-3 col-xl-2 d-flex align-items-center justify-content-center justify-content-sm-start",
              ].join(" ")}
            >
              <a href={client.url} target="blank">
                {client.logo}
              </a>
            </div>
          ))}
        </div>
        <Link
          to={`/clients`}
          className={[
            styles.allClients,
            "text-decoration-none font-medium text-white d-flex justify-content-center justify-content-sm-start",
          ].join(" ")}
          onMouseEnter={handleLinkMouseEnter}
          onMouseLeave={handleLinkMouseLeave}
        >
          All clients
        </Link>
      </div>
      {<Cursor opacity={opacity} />}
    </div>
  );
};

export default Clients;
