const BnsLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="80px"
      height="19px"
      viewBox="0 0 80 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>5D753640-867E-4A04-8687-C6AF8F2E26E6</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="1"
      >
        <g
          id="landing"
          transform="translate(-309, -2713)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-11" transform="translate(100, 2657)">
            <g id="Group-8" transform="translate(209, 0)">
              <g id="Group" transform="translate(0, 56)">
                <g id="Group_59" transform="translate(0, 0.2247)">
                  <circle
                    id="Ellipse_16"
                    cx="8.9830405"
                    cy="8.9830405"
                    r="8.9830405"
                  ></circle>
                </g>
                <g id="Group_60" transform="translate(26.5983, 0)">
                  <path
                    d="M0.00122010737,0.271630636 L9.38384577,0.271630636 C13.7140068,0.289932246 15.8792907,1.86692102 15.8796974,5.00259697 C15.911617,5.9841578 15.5669916,6.9407717 14.9164226,7.67646227 C14.161646,8.44556333 13.1568631,8.91961143 12.0833333,9.0130899 L12.0833333,9.0393222 C13.2678617,9.11913213 14.3898154,9.60027687 15.2641532,10.4034022 C15.9857518,11.1554786 16.3714249,12.1680906 16.3329673,13.2096492 C16.418677,14.5754898 15.8172926,15.8940501 14.7297462,16.7247785 C13.6609322,17.5186617 12.0656418,17.9151966 9.94387507,17.9143857 L0,17.9143857 L0.00122010737,0.271630636 Z M8.23694487,7.275657 C9.60915893,7.275657 10.2954693,6.74999407 10.2958764,5.6986682 C10.2962827,4.64734237 9.60997233,4.12167943 8.23694487,4.12167943 L5.24280137,4.12167943 L5.24280137,7.275657 L8.23694487,7.275657 Z M8.69265497,14.2253886 C10.064869,14.2253886 10.7507727,13.6909815 10.7503664,12.6221675 C10.7503664,11.5704349 10.0644623,11.044772 8.69265497,11.0451782 L5.24341143,11.0451782 L5.24341143,14.2253886 L8.69265497,14.2253886 Z"
                    id="Path_94"
                  ></path>
                  <polygon
                    id="Path_95"
                    points="35.1567837 17.9143832 29.170937 17.9143832 24.3594436 9.17292393 23.2375549 6.76809233 23.1844802 6.76809233 23.2906296 10.2698005 23.2906296 17.9143832 18.5322108 17.9143832 18.5322108 0.271630636 24.4936554 0.271630636 29.3045388 9.0130899 30.4538799 11.4197517 30.4801122 11.4197517 30.3739629 7.91804353 30.3739629 0.27224069 35.158004 0.27224069"
                  ></polygon>
                  <path
                    d="M49.88714,0.685857087 C51.2059603,1.20573294 52.4026567,1.99342139 53.4016593,2.99918066 L50.728404,6.44781413 C49.5008597,4.9100478 47.6159143,4.0467089 45.6497073,4.12167943 C45.0307917,4.0887858 44.4139633,4.21744937 43.8598097,4.4950323 C43.4747993,4.67561833 43.225915,5.0593544 43.2180333,5.48453937 C43.2180333,5.80481757 43.4394827,6.05493957 43.885432,6.2336853 C44.6073353,6.48478957 45.3497253,6.6725814 46.1041973,6.7949347 C47.51817,7.00956837 48.8977527,7.40933137 50.2074183,7.98392933 C51.1169887,8.37815157 51.896083,9.02228467 52.454246,9.8415428 C52.9496213,10.6825186 53.1948967,11.6473247 53.1612983,12.6227775 C53.1886477,13.7174343 52.8292047,14.7866909 52.146169,15.6425433 C51.4189587,16.5149736 50.4668387,17.1717318 49.3929967,17.5416404 C48.1314737,17.9862658 46.8010737,18.2035582 45.4636407,18.1834169 C43.7992417,18.2350994 42.1376173,18.0137693 40.544778,17.5282192 C39.261195,17.0708612 38.0797897,16.3661633 37.067472,15.4540367 L39.6065153,11.7107473 C40.247724,12.4950011 41.067842,13.1138555 41.9979257,13.5152861 C42.9889453,13.9006747 44.046129,14.0872366 45.1091997,14.0643344 C46.7319423,14.0643344 47.5431103,13.6722732 47.5427037,12.8881509 C47.5427037,12.5495711 47.305393,12.2817575 46.8344313,12.0859303 C46.092537,11.8188623 45.3272283,11.6219694 44.5485603,11.4978385 C43.323897,11.285107 42.1207433,10.9631364 40.953514,10.5357839 C40.020378,10.1885024 39.1885843,9.6139841 38.533431,8.8642368 C37.8598183,8.03174033 37.517235,6.97987683 37.5713763,5.91035683 C37.5525107,4.813165 37.879966,3.73794137 38.5071987,2.83751643 C39.1966123,1.89455762 40.1434613,1.17054631 41.2341387,0.75235294 C42.595957,0.222671873 44.0492257,-0.0319456311 45.510005,0.00320701461 C46.997975,-0.0304266732 48.4800767,0.200719203 49.88714,0.685857087 Z"
                    id="Path_96"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BnsLogo;
