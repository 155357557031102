import { Link, useLocation } from "react-router-dom";
import styles from "./ServicesPageFooter.module.scss";

const ServicesPageFooter = ({ servicesList }) => {
  const location = useLocation();

  const currentIndex = servicesList.findIndex(
    (service) => `/services/${service.route}` === location.pathname
  );

  const nextIndex = (currentIndex + 1) % servicesList.length;

  const nextService = servicesList[nextIndex];

  return (
    <footer className="bg-black text-white pt-8 pt-lg-16 pb-25">
      <div className="container">
        <div className="row mb-5 mb-lg-12">
          <div className="col-12 mb-5 mb-lg-8">
            <div className="d-flex flex-column offset-1">
              <div
                className={[styles.footerTitle, "font-extra-large mb-4"].join(
                  " "
                )}
              >
                Next Service
              </div>
              {nextService && (
                <Link
                  to={`/services/${nextService.route}`}
                  className={[
                    styles.serviceFooter,
                    "text-decoration-none text-white font-extra-large",
                  ].join(" ")}
                >
                  {nextService.name}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ServicesPageFooter;
